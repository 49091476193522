import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import Button from "~/components/button";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={4}
        lesson={"Lesson 1"}
        color={"hs"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <ContentWrapper>
          <H2 underline>Step 4</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={true}
              setFullScreen={setStudentFullScreen}
            >
              <p>During the lessons in this module, your task will be to develop and test a rubric that people can use to evaluate websites and the information presented on them. A <strong>rubric</strong> is a guide that can help you identify what is good, bad, or unclear about the information you find. The rubric lists criteria or evidence that can help people make better decisions.</p>
              <p>In this lesson, you will start putting together a rubric that can help people make decisions about websites they visit. In later lessons, you will add to the rubric and then test it before giving it to others to use.</p>
              <p>Click on the link below to get a copy of the rubric. Read over the criteria listed in the first category, "Lesson 1 Website Quality Initial Screen."</p>

              <div className="flex flex-col lg:flex-row">
                <Button
                  action="secondary"
                  className="my-2 lg:m-2"
                  color="indigo"
                  href="/files/hs/lesson_1/HS__S_full_rubric-scl_final.aw.docx"
                  icon="download"
                  title="Lesson 1 rubric"
                >
                  Rubric
                </Button>
              </div>

            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={true}
              setFullScreen={setTeacherFullScreen}
            >
              <p>Students are presented with the idea that they are developing a rubric to give to someone else to learn how to evaluate information found on websites. In the field test, students were more engaged in the rubric with this approach compared to developing it for their own use. (Students often thought they did not need to write things down, potentially due to thinking that they are already skilled internet users.)</p>
              <p>Have students access a copy of the rubric. (See the Materials list.) This rubric includes several criteria that students will look for when they examine websites. (Students will also be adding criteria to their rubrics in later steps.) Allow a few minutes for students to read over the criteria listed in the first section.</p>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
